import request from '@/utils/request'

export function orderList(params) {
    return request({
        url: '/order/list',
        method: 'post',
        data: params
    })
}
export function getOrder(id) {
    return request({
        url: '/order/get/'+id,
        method: 'get',
        data: null
    })
}
