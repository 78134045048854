import axios from 'axios'
import { Loading, Message } from 'element-ui'

var loadingInstance;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
   // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: "https://dev.wozaizher.com.cn",
   // 超时
   timeout: 20000
})
// request拦截器
service.interceptors.request.use(config => {
    loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    //const token =  window.localStorage.getItem("token");
    //config.headers.Authorization = token;
    return config
})

// response拦截器
service.interceptors.response.use(res => {
        loadingInstance.close();
        const code = res.data.code
        const msg = res.data.message
        if(code !== 200) {
            Message.error(msg)
        }
        return res.data
    },
    error => {
        //ElMessage.error(error);
        console.log(error)
        loadingInstance.close();
    }
)

export default service