import request from '@/utils/request'

export function resumeList(params) {
    return request({
        url: '/resume/list',
        method: 'post',
        data: params
    })
}
export function getResume(id) {
    return request({
        url: '/resume/get/'+id,
        method: 'get',
        data: null
    })
}

