import request from '@/utils/request'

export function getMapMarker(type, city) {
    return request({
        url: '/marker/query?type='+type+'&city='+city,
        method: 'get',
        data: null
    })
}

